import styled from "styled-components";
export const EmptyWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  .empty-content {
    width: 80%;
    height: 80%;
    margin: 0 auto;
    @media (max-width: 992px) {
      width: 90%;
    }
    @media (max-width: 520px) {
      width: 100%;
    }
    & > img {
    width: 70%;
    height: 100%;
    display: block;
    margin: 0 auto;
    object-fit: contain;
      @media (max-width: 768px) {
        width: 80%;
      }
      @media (max-width: 520px) {
      width: 90%;
    }
  }
  }

`;
