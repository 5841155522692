export const data = [
     {
          id: 1,
          name: "/assets/images/Profile CNTT I-TECK.INAN.jpg"
     },
     {
          id: 2,
          name: "/assets/images/Profile CNTT I-TECK.INAN-2.jpg"
     },
     {
          id: 3,
          name: "/assets/images/Profile CNTT I-TECK.INAN-3.jpg"
     },
     {
          id: 4,
          name: "/assets/images/Profile CNTT I-TECK.INAN-4.jpg"
     },
     {
          id: 5,
          name: "/assets/images/Profile CNTT I-TECK.INAN-5.jpg"
     },
     {
          id: 6,
          name: "/assets/images/Profile CNTT I-TECK.INAN-6.jpg"
     },
     {
          id: 7,
          name: "/assets/images/Profile CNTT I-TECK.INAN-7.jpg"
     },
     {
          id: 8,
          name: "/assets/images/Profile CNTT I-TECK.INAN-8.jpg"
     },
     {
          id: 9,
          name: "/assets/images/Profile CNTT I-TECK.INAN-9.jpg"
     },
     {
          id: 10,
          name: "/assets/images/Profile CNTT I-TECK.INAN-10.jpg"
     },
     {
          id: 11,
          name: "/assets/images/Profile CNTT I-TECK.INAN-11.jpg"
     },
     {
          id: 12,
          name: "/assets/images/Profile CNTT I-TECK.INAN-12.jpg"
     },
     {
          id: 13,
          name: "/assets/images/Profile CNTT I-TECK.INAN-13.jpg"
     },
     {
          id: 14,
          name: "/assets/images/Profile CNTT I-TECK.INAN-14.jpg"
     },
     {
          id: 15,
          name: "/assets/images/Profile CNTT I-TECK.INAN-15.jpg"
     },
     {
          id: 16,
          name: "/assets/images/Profile CNTT I-TECK.INAN-16.jpg"
     },
     {
          id: 17,
          name: "/assets/images/Profile CNTT I-TECK.INAN-17.jpg"
     },
     {
          id: 18,
          name: "/assets/images/Profile CNTT I-TECK.INAN-18.jpg"
     },
     {
          id: 19,
          name: "/assets/images/Profile CNTT I-TECK.INAN-19.jpg"
     },
     {
          id: 20,
          name: "/assets/images/Profile CNTT I-TECK.INAN-20.jpg"
     },
];