import react from "react";
import { EmptyWrapper } from "./style";
const Empty = props => {
     return (
          <EmptyWrapper className="empty">
               <div className="empty-content">
               <img src="/assets/images/baotri.png" alt="bảo trì" />
               </div>
          </EmptyWrapper>
     );
}
export default Empty;