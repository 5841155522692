import styled, { keyframes } from "styled-components";

export const SliderWrapper = styled.div`
  width: 50%;
  z-index: 3;
  margin: 1rem auto;
  @media (max-width: 1200px) {
    height: 100vh;
  }
  @media (max-width: 992px) {
    width: 70%;
    height: auto;
  }
  @media (max-width: 550px) {
    width: 90%;
    height: auto;
  }
`;
export const SliderItem = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  & > img {
    width: 100%;
    object-fit: cover;
    @media (max-width: 768px) {
      height: 90vh;
    }
    @media (max-width: 550px) {
      height: auto;
    }
  }
`;
