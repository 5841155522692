import "./App.css"
import React from 'react';
import Empty from "./Empty";
import Profile from "./Profile";
import { Route, Switch } from "react-router-dom"
function App() {
   return (
      <div className="app">
         <Switch>
            <Route path="/" exact component={Profile} />
            <Route exact component={Empty} />
         </Switch>

      </div>
   );
}

export default App;
