
import { SliderItem, SliderWrapper } from "./style";
import { useEffect, useState } from "react";
import { data } from "./data";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const Profile = () => {
     const settings = {
          dots: false,
          fade: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          cssEase: "linear"
     };


     return (
          <div className="App">
                <video
                className="video"
                playsInline
                autoPlay
                preload
                muted
                loop
                src="/assets/video/file-video.mp4"
                width="100%"
               
                >

                </video>
               <SliderWrapper className="slider">
                    <Slider {...settings}>
                         {data.map(item => {
                              return <SliderItem
                                   className="slider-item"
                                   key={item.id}
                                   src={item.name}
                              >
                                   <img src={item.name} alt=""/>
                              </SliderItem>
                         })}
                    </Slider>
               </SliderWrapper>
          </div>
     );
}
export default Profile